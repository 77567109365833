<template>
    <div class="background-cream" style="padding-top: 250px; padding-bottom: 150px">
        <template v-if="!isLoggedIn">
        
            <v-container>
                <v-row justify="center">
                    <v-col style="max-width: 450px;">
                        <v-card color="">
                            <v-card-title class="text-h5">
                                Sign in
                            </v-card-title>

                            <v-card-subtitle outlined class="mt-6">
                                <v-row>
                                    <v-col>
                                        <v-text-field v-model="username" label="Username" :disabled="isLoggedIn" outlined :error="isError"
                                        color="black"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>

                                        <v-text-field type="password" v-model="password" label="Password" :disabled="isLoggedIn" outlined :error="isError"
                                        color="black"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-subtitle>

                            <v-card-actions>
                                <v-btn text @click="signIn" :disabled="isLoggedIn">
                                    Sign in
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>

        </template>

        <template v-if="isLoggedIn">
        
            <v-container>
                <v-row>
                    <v-col>
                        <table style="width: 100%;">
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Preferred Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <template v-for="(prospect,p) in prospects">
                                <tr :key="'prospect-'+p">
                                    <td class="text-center">
                                        {{prospect.firstName}}
                                    </td>
                                    <td class="text-center">
                                        {{prospect.lastName}}
                                    </td>
                                    <td class="text-center">
                                        {{prospect.email}}
                                    </td>
                                    <td class="text-center">
                                        {{prospect.preferredDate}}
                                    </td>
                                    <td class="text-center">
                                        <template v-if="prospect.status == 'Pending'">
                                            <v-btn color="success" max-width="300" class="mx-auto d-block my-3">accept</v-btn>
                                            <v-btn color="warning" max-width="300" class="mx-auto d-block my-3">need more info</v-btn>
                                        </template>
                                        <template v-else>
                                            {{prospect.status}}
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </v-col>
                </v-row>
            </v-container>

        </template>
    </div>
</template>

<script>
import Vue from 'vue'
import VueSession from 'vue-session'
Vue.use(VueSession,{persist: true})
export default {
    data () {
        return {
            username: '',
            password: '',
            user:{
            },
            prospects: []
        }
    },
    computed: {
        isLoggedIn: function () {
            if(Object.prototype.hasOwnProperty.call(this.user,'api_key')){
                this.$session.set('user',this.user)
                return true
            }
            return false;
        },
        isError: function () {
            if(Object.prototype.hasOwnProperty.call(this.user,'error')){
                return true;
            }
            return false;
        }
    },
    watch: {
        isLoggedIn: function () {
            if(this.isLoggedIn){
                this.$router.push('/tour-info')
            }
        }
    },
    methods: {
        signIn: function(){
            fetch('https://content.marisolmalibu.com/api/cockpit/authUser?token=23c18a9579d81592ede1f10e70819a', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    user: this.username,
                    password: this.password
                })
            })
            .then(user => user.json())
            .then(user => this.user = user)
            .then(this.getData());
        },
        getData: function (){
            fetch('https://content.marisolmalibu.com/api/collections/get/Tours?sort[_created]=-1&token='+this.user.api_key)
            .then(res => res.json())
            .then(res => this.prospects = res.entries);
        }
    }
}
</script>